<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">企业资质</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">空间规划公司同时具备规划、测绘、土地、工程咨询等高级资质的技术机构。目前具备城乡规划甲级，工程咨询甲级，市政道路工程、桥梁工程甲级，土地规划乙级，测绘乙级，排水工程乙级，风景园林工程设计乙级等资质。</span> 
        </p>
        <div style="display: inline-block;text-align:center;width: 48%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/zz/城乡规划.png" alt="" /> 
          <div style="padding: 5px 0 10px;font-weight: bold;">城乡规划甲级</div>
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/zz/土地.png" alt="" /> 
          <div style="padding: 5px 0 10px;font-weight: bold;">土地规划乙级</div>
        </div>
        <div style="display: inline-block;text-align:center;width: 100%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/zz/测绘.png" alt="" /> 
          <div style="padding: 5px 0 10px;font-weight: bold;">测绘乙级</div>
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/zz/市政设计甲级.png" alt="" /> 
          <div style="padding: 5px 0 10px;font-weight: bold;">市政道路工程、桥梁工程甲级</div>
        </div>

        <div style="display: inline-block;text-align:center;width: 48%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/zz/测绘乙级.png" alt="" />
          <div style="padding: 5px 0 10px;font-weight: bold;">测绘乙级</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 100%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/zz/风景市政乙级.png" alt="" /> 
          <div style="padding: 5px 0 10px;font-weight: bold;">排水工程、风景园林工程设计乙级</div>
        </div>
        <div style="display: inline-block;text-align:center;width: 32%;height: 400px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/zz/工程咨询资信甲级.png" alt="" /> 
          <div style="padding: 5px 0 10px;font-weight: bold;">工程咨询资信甲级</div>
        </div>
        <div style="display: inline-block;text-align:center;width: 32%;height: 400px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/zz/电子信息工程咨询乙级.png" alt="" />
          <div style="padding: 5px 0 10px;font-weight: bold;">电子信息工程咨询乙级</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 32%;height: 400px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/zz/市政咨询乙级.png" alt="" /> 
          <div style="padding: 5px 0 10px;font-weight: bold;">市政公用工程咨询乙级</div>
        </div>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>